@font-face {
  font-weight: 300;
  font-family: 'Lato-Light';
  font-style: normal;
  src: url('./res/fonts/Lato-Light.woff2') format('woff2'),
    url('./res/fonts/Lato-Light.ttf') format('ttf');
}

@font-face {
  font-weight: 400;
  font-family: 'Lato-Regular';
  font-style: normal;
  src: url('./res/fonts/Lato-Regular.woff2') format('woff2'),
    url('./res/fonts/Lato-Regular.ttf') format('ttf');
}

@font-face {
  font-weight: 700;
  font-family: 'Lato-Bold';
  font-style: normal;
  src: url('./res/fonts/Lato-Bold.woff2') format('woff2'),
    url('./res/fonts/Lato-Bold.ttf') format('ttf');
}

@font-face {
  font-weight: 400;
  font-family: 'Slabo 27px';
  font-style: normal;
  src: url('./res/fonts/Slabo 27px.woff2') format('woff2'),
    url('./res/fonts/Slabo 27px.ttf') format('ttf');
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.os-host {
  height: 100%;
}

.scanner-container {
  display: flex;
  flex: 1;
  justify-content: center;
}

.scanner-frame {
  position: relative;
  display: flex;
}

.scanner-frame video {
  flex: 1;
}

.scanner-frame canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
