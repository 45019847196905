._TmdaEmP { 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
 }._OzifDAV { 
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
 }._r_N0wPH { 
  font-family: 'Lato-Regular';
  text-align: center;
 }._jdTUsTl { 
  font-family: 'Lato-Regular';
 }._UdNbx6M { 
  font-family: 'Lato-Bold';
  display: block;
  padding: 5px;
  text-align: center;

  sub {
    color: #000;
  }
 }